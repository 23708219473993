<template>
    <div id="fullCal">
        <!-- grouped button -->
        <v-toolbar color="white" flat>

            <v-btn-toggle v-model="viewMode" class="my-4">
                <v-btn text value="calendar">Calendrier</v-btn>
                <v-btn text value="list">Liste</v-btn>
            </v-btn-toggle>
            <v-spacer />
            <v-btn :href="`webcal://${myApi}/api/airport/ics/${this.$store.state.airport.poi}`">
                <v-icon left>mdi-calendar-import</v-icon>
                {{ $t("alerts.subscribe") }}
            </v-btn>
        </v-toolbar>
        <template v-if="viewMode == 'calendar'">
            <v-sheet tile height="54" class="d-flex">
                <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-sheet>
            <v-calendar ref="calendar" v-model="value" :type="type" :events="eventsFormated" event-color="grey">
                <template v-slot:event="{ event }">
                    <span style="font-size:0.8em">{{ moment(event.start).format('HH:mm') }} - {{
                        moment(event.end).format('HH:mm') }}</span><br />
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn x-small fab elevation="0" v-bind="attrs" v-on="on" :color="getEventColor(event)" dark>
                                <v-icon x-small v-bind="attrs" fab color="white" v-on="on">{{ weatherTextToIcon(event.name)
                                }}</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ event.name }}</span>
                    </v-tooltip>
                </template>
            </v-calendar>
        </template>
        <template v-else>
            <v-list v-if="false">
                <v-subheader v-for="date in dates" :key="date">{{ date }}</v-subheader>
                <v-list-item v-for="(event, index) in events" :key="index">
                    <v-list-item-content>
                        <v-list-item-title>{{ event.dt_start }} - {{ event.dt_end }}</v-list-item-title>
                        <v-list-item-subtitle>{{ event.name }}: {{ event.min }} °C - {{ event.max }}
                            °C</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-data-table :headers="headers" :items="events" :items-per-page="-1" class="elevation-1" group-by="day"
                hide-default-footer sort-by="id" sort-asc>
                <template v-slot:[`group.header`]="item">
                    <td :colspan="headers.length">
                        {{ moment(item.group).format('dddd DD MMMM YYYY') }}
                    </td>
                </template>
                <template v-slot:[`item.dt_start`]="{ item }">
                    {{ moment(item.dt_start).format('HH:mm') }}
                </template>
                <template v-slot:[`item.dt_end`]="{ item }">
                    {{ moment(item.dt_end).format('HH:mm') }}
                </template>
                <template v-slot:[`item.name`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" fab :color="levelToColor(item.level)" v-on="on">{{
                                weatherTextToIcon(item.name) }}</v-icon>
                        </template>
                        <span>{{ item.name }}</span>
                    </v-tooltip>

                </template>
                <template v-slot:[`item.min`]="{ item }">
                    <template v-if="item.name != 'Fog'">
                        {{ item.min }} {{ unitFromText(item.name) }}
                    </template>
                </template>
                <template v-slot:[`item.max`]="{ item }">
                    <template v-if="item.name != 'Fog'">
                        {{ item.max }} {{ unitFromText(item.name) }}
                    </template>
                </template>

            </v-data-table>
        </template>

    </div>
</template>
  
<script>
import api from '@/api';
import moment from 'moment';
export default {
    name: 'AirportAlertCalendar',
    computed: {
        eventsFormated() {
            if(!this.events.length) {
                return [];
            }
            return this.events.map((event, index) => {
                return {
                    id: index,
                    name: event.name,
                    start: event.dt_start,
                    end: event.dt_end,
                    min: event.min,
                    max: event.max,
                    level: event.level
                }
            });
        },
        weekday() {
            return ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
        },
        type() {
            return 'week'
        },
        mode() {
            return 'stack'
        },
        headers() {
            return [
                {
                    text: 'Début',
                    align: 'start',
                    value: 'dt_start'
                },
                {
                    text: 'Fin',
                    value: 'dt_end'
                },
                {
                    text: 'Nom',
                    value: 'name'
                },
                {
                    text: 'Min',
                    value: 'min'
                },
                {
                    text: 'Max',
                    value: 'max'
                }
            ]
        }
    },
    data() {
        return {
            value: '',
            viewMode: 'list',
            moment: moment,
            currentMonth: '',
            view: 'listMonth',
            disableToday: true, // Mettez à jour selon la logique souhaitée
            dates: [], // Remplissez avec les dates de la réponse de l'API
            events: [], // Remplissez avec les événements de la réponse de l'API
            myApi: process.env.VUE_APP_MY_API_URI.replace('https://', '')
        }
    },
    methods: {
        getEventColor(event) {
            return this.levelToColor(event.level);
        },
        levelToColor(level) {
            switch (level) {
                case 1:
                    return 'yellow darken-1';
                case 2:
                    return 'orange';
                case 3:
                    return 'red';
                default:
                    return 'grey';
            }
        },
        unitFromText(text) {
            switch (text) {
                case 'Vent':
                    return 'knts';
                case 'Temp min':
                case 'Temp max':
                case 'Temp sol':
                    return '°C';
                default:
                    return '';
            }
        },
        weatherTextToIcon(text) {
            switch (text) {
                case 'Vent':
                    return 'mdi-weather-windy';
                case 'Temp min':
                    return 'mdi-thermometer-chevron-down';
                case 'Temp max':
                    return 'mdi-thermometer-chevron-up';
                case 'Temp sol':
                    return 'mdi-thermometer';
                case 'Fog':
                    return 'mdi-weather-fog';
                case 'Pluie':
                    return 'mdi-weather-rainy';
                case 'Neige':
                    return 'mdi-weather-snowy';
                case 'Houle':
                    return 'mdi-waves';
                default:
                    return 'mdi-weather-sunny';

            }
        },
        prev() {
            // Logique pour aller au mois précédent
        },
        today() {
            // Logique pour aller au mois en cours
        },
        next() {
            // Logique pour aller au mois suivant
        },
        fetchData() {
            // console.log('fetch data')
            api.airport.getEvents(this.$store.state.airport.poi).then((response) => {
                let events = response;
                if (!events.length) {
                    return;
                }
                events.map((map, index) => {
                    map.id = index;
                    map.dt_start = moment(map.dt_start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                    map.dt_end = moment(map.dt_end, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                    // map.day = moment(map.dt_start , 'YYYY-MM-DD HH:mm:ss').format('dddd DD MMMM YYYY');
                    map.day = moment(map.dt_start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
                });
                // sort event by date asc
                events = events.reverse();
                this.events = events;
                // console.log('events', this.events);
            });
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>
  
<style>
.v-event-timed.grey.white--text {
    overflow: hidden;
}
</style>