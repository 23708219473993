<template>
  <v-dialog v-model="localIsVisible" @click:outside="closeDialog" max-width="600">
    <v-card>
      <v-card-title class="headline">Légende</v-card-title>
      <br>
      <v-card-text>
        <h3>Alertes</h3>
        <br>
        <p>
          Les alertes ont trois seuils possibles:  <strong style="color: yellow; background-color: #ececec; padding: 1px 3px; border-radius: 3px">Seuil bas</strong>,
          <strong style="color: orange; background-color: #ececec; padding: 1px 3px; border-radius: 3px">Seuil moyen</strong>,
          <strong style="color: red; background-color: #ececec; padding: 1px 3px; border-radius: 3px">Seuil haut</strong>.
          Les alertes sont gérées dans la section <strong style="color: blue; background-color: #ececec; padding: 1px 3px; border-radius: 3px">Alertes > Parameters</strong>.
          Vous y retrouvez les différents seuils, paramétrables pour chaque aéroport.
        </p>
        <br>

        <h3>Paramètres d'Alertes</h3>
        <br>
        <ul>
          <li><strong>Température du sol</strong>: Température du sol en °C.</li>
          <li><strong>Température</strong>: Température de l'air en °C.</li>
          <li><strong>Vent</strong>: Vitesse du vent en knts.</li>
          <li><strong>Pluie</strong>: Cumul des précipitations de pluie en mm/h.</li>
          <li><strong>Neige</strong>: Cumul des précipitations de neige en mm/h.</li>
          <li><strong>Black Ice</strong>: Indice de risque de 1 à 5.</li>
          <li><strong>Thunderstorm</strong>: Indice de risque de 1 à 5.</li>
          <li>
            <strong>Crosswind</strong>: Vitesse du vent en knts avec une direction perpendiculaire à plus ou moins
            22,5 degrés de l'axe piste (cf. figure ci-dessous).
          </li>
        </ul>
        <img style="width: 70%" src="/crosswind.png"  alt="explication graphique de Crosswind"/>

        <h3>Couverture Nuageuse</h3>
        <br>
        <ul>
          <li><strong>Basse altitude</strong>: sol -> 6500 ft</li>
          <li><strong>Moyenne altitude</strong>: 6500 -> 23000 ft</li>
          <li><strong>Haute altitude</strong>: 23000 -> 40000 ft</li>
        </ul>
        <br>
        <h3>Types d'État de la Chaussée</h3>
        <br>
        <ul>
          <li>Eau: Présence d'une lame d'eau sur la chaussée.</li>
          <li>Neige: Présence de neige sur la chaussée.</li>
          <li>Mix Water/Ice</li>
          <li>Condensation: Chaussée humide.</li>
          <li>Glace Fondante: Présence de glace/neige en train de fondre sur la chaussée.</li>
          <li>Verglas</li>
          <li>>Pluie sur Sol Gelé: Pluie verglaçante en surfusion ou non.</li>
        </ul>
        <br>

        <h3>Dates et Heures</h3>
        <br>
        <p>Toutes les dates et heures sont affichées en <strong>UTC</strong>.</p>
        <br>

        <h3>Prévisions</h3>
        <br>
        <p>Les prévisions standards et graphiques sont basées sur un modèle unique de prévisions météorologiques.</p>
        <br>

        <h3>Fournisseurs des Modèles de Prévision Météo</h3>
        <br>
        <ul>
          <li><strong>HD2, HD10</strong>: Previmeteo</li>
          <li><strong>GFS</strong>: NOAA (USA)</li>
          <li><strong>DWD</strong>: Icon</li>
          <li><strong>ECMWF</strong>: Europe</li>
          <li><strong>Impacts de foudre</strong>: Earth Network</li>
          <li><strong>Radar, Sat</strong>: MeteoFrance, Rainviewer</li>
          <li><strong>Carte technique</strong>: Windy</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Fermer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PopUpHelpComponent',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      localIsVisible: this.isVisible
    };
  },
  watch: {
    isVisible(newValue) {
      this.localIsVisible = newValue;
    },
    localIsVisible(newValue) {
      if (!newValue) {
        this.$emit('close'); // Emit event to notify parent
      }
    }
  },
  methods: {
    closeDialog() {
      this.localIsVisible = false; // Update local visibility
    }
  }
}
</script>

<style scoped>
/* Ajoutez ici votre style personnalisé si nécessaire */
</style>
