import { App } from '@capacitor/app';
import Vue from 'vue';
import axios from 'axios';
import VueRouter from 'vue-router';

import api from '@/api';
import store from '@/store';
import Home from '@/views/Home.vue';
import moment from "moment/moment";

App.addListener('appUrlOpen', (event) => {
  const slug = event.url.split('.com').pop();

  // Only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    beforeEnter: logRouting,
    component: () => import('@/views/Login'),
  },
  {
    path: '/',
    name: 'Home',
    beforeEnter: authMiddleware,
    component: Home,
  },
  {
    path: '/dashboard/:geoname/:airport',
    name: 'Dashboard Airport',
    beforeEnter: authMiddleware,
    component: Home,
  },
  {
    path: '/webcam-widget',
    name: 'WidgetWebcam',
    component: ()=>import('@/views/WebcamWidget'),
  },
  {
    path: '/documents',
    name: 'DocumentView',
    beforeEnter: authMiddleware,
    component: ()=>import('@/views/Documents'),
  },
  {
    path: '/documentsnew',
    name: 'DocumentNew',
    beforeEnter: authMiddleware,
    component: ()=>import('@/views/DocumentsNew'),
  },
  {
    path: '/ville.php?id=2977281&code_pays=FR',
    redirect: '/forecasts',
    beforeEnter: authMiddleware,
    component: () => import('@/components/Forecasts/Resum'),
  },
  {
    path: '/plans',
    name: 'Plans',
    beforeEnter: authMiddleware,
    component: () => import('@/views/Plans'),
  },
  {
    meta: {
      title: 'maps',
    },
    path: '/maps',
    name: 'Radar/Satellite',
    beforeEnter: authMiddleware,
    component: () => import('@/views/Maps'),
    children: [
      {
        path: 'windy',
        component: () => import('@/components/Maps/Windy')
      },
      {
        path: 'weathermap',
        component: () => import('@/components/Maps/Windy'),
        beforeEnter: authMiddleware,
      },
      {
        path: 'radar/:geoname?/:cityname?',
        component: () => import('@/components/Maps/Radar'),
        beforeEnter: authMiddleware,
      },
      {
        path: 'stormwatch/:geoname?/:cityname?',
        component: () => import('@/components/Stormwatch/Map'),
        beforeEnter: authMiddleware,
      },
      {
        path: 'satellite',
        component: () => import('@/components/Maps/Satellite'),
      },
      {
        path: 'hail',
        component: () => import('@/components/HailV2/Map'),
        beforeEnter: authMiddleware,
      },
    ],
  },
  {
    path:'/hail',
    component: () => import('@/views/HailV2'),
    beforeEnter: authMiddleware,
    children: [
      {
        path: '',
        name: 'Hail',
        component: () => import('@/components/HailV2/Map'),
      }
    ]
  },
  {
    path: '/admin',
    beforeEnter: authMiddleware,
    component: () => import('@/views/Admin'),
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import('@/components/Admin/UsersList'),
        meta: { rules: 'admin' },
      },
    ],
  },
  {
    path:'/messages',
    beforeEnter: authMiddleware,
    component: () => import('@/views/Messages'),
    children:[
      {
        path: '',
        name: 'Messages',
        component: () => import('@/components/Messages/MessagesList'),
      },
      {
        path: ':id',
        component: () => import('@/components/Messages/MessageView'),
      }
    ]
  },
  {
    path: '/alertes',
    component: () => import('@/views/Alerts'),
    beforeEnter: authMiddleware,
    children: [
      { path: '', component: () => import('@/components/Alertes/Setting') },
      {
        path: 'v2',
        component: () => import('@/components/Alertes/NewAlerts'),
        meta: { rules: 'alerts' },
      },
      {
        path: 'v2/list',
        component: () => import('@/components/Alertes/AlertsList'),
        //meta: { rules: 'alerts' },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register'),
  },
  {
    path: '/upgrade',
    name: 'Upgrade',
    component: () => import('@/views/Upgrade'),
  },
  {
    path: '/weathermaps',
    component: () => import('@/components/Weathermap/Weathermap'),
    beforeEnter: authMiddleware,
    // meta: { rules: 'weathermaps' },
  },
  {
    path: '/publicweathermaps',
    component: () => import('@/components/Weathermap/Weathermap'),
    beforeEnter: isPublicAuth,
    // meta: { rules: 'weathermaps' },
  },
  {
    path: '/stations',
    component: () => import('@/views/Stations'),
    beforeEnter: authMiddleware,
    children: [
      {
        path: ':id_station',
        component: () => import('@/components/Stations/Data'),
      },
      {
        path: 'create',
        component: () => import('@/components/Stations/Create'),
      },
      { path: ':geoname?/:cityname?',
        component: () => import('@/components/Stations/PublicList') },
    ],
  },
  {
    path: '/profil',
    beforeEnter: authMiddleware,
    component: () => import('@/views/Profil'),
    children: [
      {
        path: '',
        name: 'Profil',
        component: () => import('@/components/Profil/Profil'),
      },
      {
        path: 'referrer',
        name: 'Referrer',
        component: () => import('@/components/Profil/Referrer'),
      },
      {
        path: 'stormwatch',
        name: 'Stormwatch',
        component: () => import('@/components/Admin/StormwatchAdmin'),
      },
    ],
  },
  {
    path:'/airport',
    beforeEnter: authMiddleware,
    component: () => import('@/views/Airport.vue'),
    children: [
      {
        path: '',
        name: 'Airport',
        component: () => import('@/components/Airport/AirportCalendar.vue'),
      },
      {
        path: 'alerts/:geoname?/:airport?',
        name: 'AirportAlerts',
        component: () => import('@/components/Airport/AirportAlertCalendar.vue'),
        beforeEnter: authMiddleware,
      },
      {
        path: 'alerts/form/:geoname?/:cityname?',
        name: 'AirportAlerts',
        component: () => import('@/components/Airport/AlertForm.vue'),
        beforeEnter: authMiddleware,
      }
    ]
  },
  {
    meta: {
      title: 'Forecasts',
    },
    path: '/forecasts',
    name: 'Prévisions',
    beforeEnter: authMiddleware,
    component: () => import('@/views/Forecasts'),
    children: [
      {
        path: 'alerts/:geoname/:cityname',
        component: () => import('@/components/Forecasts/Alerts'),
      },
      {
        path: ':geoname?/:cityname?/long',
        component: () => import('@/components/Forecasts/ForecastsLong'),
        // meta: { rules: 'forecast_14' },
      },
      {
        path: ':geoname?/:cityname?/roadcast',
        component: () => import('@/components/Forecasts/Roadcast'),
        meta: { rules: 'roadcast' },
      },
      {
        path: ':geoname?/:cityname?/multi',
        component: () => import('@/components/Forecasts/MultiModel'),
      },
      {
        path: ':geoname?/:cityname?/multichart',
        component: () => import('@/components/Forecasts/ForecastMulti'),
        // meta: {  },
      },
      {
        path: ':geoname?/:cityname?/resum',
        component: () => import('@/components/Forecasts/Resum'),
      },
      {
        path: ':geoname?/:cityname?',
        component: () => import('@/components/Forecasts/Forecasts'),
      },
    ],
  },
]

async function logRouting (to, from, next) {
  next()
  try{
	  throw new Error(to.path)
  }catch(err)
  {
	  console.log(err);
  }
}

function isPublicAuth(to, from, next) {
  console.log(to);
  return to.query.token == 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.o8-mX-tixfKG8kh-tP6zgiAbNQuSccVztGimM8MUEjU' ? next() : false; 
}

async function authMiddleware(to, from, next) {
  store.state.routerLoading = true;

  if (store.state.token === null) {
    store.state.routerLoading = false;
    return next();
  }

  axios.defaults.headers.common = {
    Authorization: `Bearer ${store.state.token}`,
  };

  store.state.isLogged = true;

  try {
    if (!store.state.user.id_user) {
      store.state.mainloading = true;
      if(localStorage.getItem('user')){
        api.users.me(store.state.token).then(async ({ user }) => {
          api.users.get(user.id_user, this.$wm.flag).then(async (userdata) => {
            api.alert.get(user.id_user).then(async (alerts) => {
              store.state.mainloading = false;
              store.dispatch('setUser', userdata);
              store.dispatch('setAlerts', alerts);
            });
          });
        });
      }else{
        let { user } = await api.users.me(store.state.token);
        let userdata = await api.users.get(user.id_user, this.$wm.flag);
        let alerts = await api.alert.get(user.id_user);
        store.state.mainloading = false;
        store.dispatch('setUser', userdata);
        store.dispatch('setAlerts', alerts);
      }
    }

    if (to.meta.rules) {
      if (store.state.user.features.find((map) => map == to.meta.rules)) {
        next();
        window.mt('send', 'pageview', {email: store.state.user.user_email, firstname: `${store.state.user.user_prenom} ${store.state.user.user_nom}`});
      } else {
        next('/login');
      }
    } else {
      next();
      window.mt('send', 'pageview', {email: store.state.user.user_email, firstname: `${store.state.user.user_prenom} ${store.state.user.user_nom}`});
    }
  } catch (err) {
    console.error(err);
    store.state.mainloading = false;
    next(`/login`);
  }

  store.state.routerLoading = false;
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  setTimeout(()=>{
    try{
      // window.mt('send', 'pageview', {email: store.state.user.user_email, firstname: `${store.state.user.user_prenom} ${store.state.user.user_nom}`})
      window.scrollTo(0,0);
    }catch(err)
    {
      console.log(err);
    }
  }, 5000)
  next();
})
async function getConditionalComponent() {
  return store.state.airport
      ? () => import('@/components/Airport/AirportCalendar')
      : () => import('@/components/Forecasts/Resum');
}
export default router
