import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

import i18n from '@/i18n';
import { loader } from './loader'

import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

import moment from 'moment';
import 'moment-timezone';

Vue.use(Vuex);

const getDefaultUser = () => ({
	id_user: null,
	bulletins_pdf: [],
	limits: [],
	user_email: null,
	user_name: null,
	user_prenom: null,
	user_nom: null,
	user_avatar: null,
	user_lang: null,
	favoris: [],
	features: [],
	stations: [],
	roles: [],
	modele_defaut:null
});

export default new Vuex.Store({
	state: {
		/* Loader on page change */
		model:null,
		routerLoading: false,
		mainloading:false,
		alerts:[],
		/* User */
		userName: null,
		userEmail: null,
		userAvatar: null,
		token: localStorage.getItem('token') || null,
		isLogged: false,
		user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : getDefaultUser(),

		/* FormScreen - fullscreen form layout (e.g. login page) */
		isFormScreen: false,

		/* Aside */
		isAsideMobileExpanded: false,
		airport: null,
	},
	mutations: {
		SET_TOKEN(state, token) {
			state.token = token;
			axios.defaults.headers.common = {'Authorization': `Bearer ${token}`} 
			localStorage.setItem('token', token);
		},
		SET_USER(state, user) {
			state.user = user;
			localStorage.setItem('user', JSON.stringify(user));
			if (user?.lang && !i18n.locale) {
				i18n.locale = user.lang.toLowerCase();
			}
			// localStorage.setItem('user', JSON.stringify(user));
		},
		SET_MODEL(state, model) {
			state.model = model;
		},
		SET_ALERTS(state, alerts) {
			state.alerts = alerts;
		},
		/* A fit-them-all commit */
		basic(state, payload) {
			state[payload.key] = payload.value;
		},
		SET_AIRPORT(state, airport) {
			const modelsString = airport.modeles;
			const modelsArray = modelsString.split(',');
			airport.modeles = modelsArray;
			state.airport = airport;
			// console.log('timezone', airport);
			moment.tz.setDefault(airport.timezone);
		},

		/* User */
		user(state, payload) {
			if (payload.name) {
				state.userName = payload.name;
			}
			if (payload.email) {
				state.userEmail = payload.email;
			}
			if (payload.avatar) {
				state.userAvatar = payload.avatar;
			}
		},
	},
	actions: {
		setToken({ commit }, token) {
			commit('SET_TOKEN', token);
		},
		clearNotifications() {
			const platform = Capacitor.getPlatform();
			if(!['ios', 'android'].includes(platform)) return;
			PushNotifications.removeAllDeliveredNotifications();
		},
		setModel({ commit }, model) {
			commit('SET_MODEL', model);
		},
		setIsLogged({ commit }, isLogged) {
			commit('basic', {
				key: 'isLogged',
				value: isLogged,
			});
		},
		setUser({ commit }, user) {
			commit('SET_USER', user);
		},
		setAlerts({ commit }, alerts) {
			commit('SET_ALERTS', alerts);
		},
		logout({ dispatch }) {
			dispatch('setToken', null);
			dispatch('setUser', getDefaultUser());
			dispatch('setIsLogged', false);
			dispatch('setAlerts', []);
			localStorage.removeItem('token');
		},
		asideMobileToggle({ commit, state }, payload = null) {
			const isShow = payload !== null ? payload : !state.isAsideMobileExpanded;

			document
				.getElementById('app')
				.classList[isShow ? 'add' : 'remove']('ml-60');

			document.documentElement.classList[isShow ? 'add' : 'remove'](
				'm-clipped'
			);

			commit('basic', {
				key: 'isAsideMobileExpanded',
				value: isShow,
			});
		},
		formScreenToggle({ commit }, payload) {
			commit('basic', {
				key: 'isFormScreen',
				value: payload,
			});

			document.documentElement.classList[payload ? 'add' : 'remove'](
				'form-screen'
			);
		},
		setAirport({ commit }, airport) {
			commit('SET_AIRPORT', airport);
		},
	},
	modules: {
		loader,
	},
});
