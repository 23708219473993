<template>
  <v-container fluid class="grey lighten-3">

    <v-row>
      <v-col v-if="isLogged" md="12" cols="12">
        <v-card v-if="$airside && bulletin">
          <v-card-title>
            <span>{{ $t("home.newsletter") }}</span>
          </v-card-title>
          <v-card-text v-html="bulletin">
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="isLogged" md="12" cols="12">
        <v-card>
          <v-card-title v-if="!$airside">
            <span>Prévisions</span>
          </v-card-title>
          <v-card-title v-else>
            <span>{{ $t("alerts.runningAlerts") }}</span>
          </v-card-title>
          <v-card-text>
            <v-row v-if="!$airside">
              <template v-if="user.favoris.length > 0">
                <v-col md="6" cols="12" v-for="(favoris, i) in user.favoris" :key="`fav${i}`">
                  <v-card elevation="0" color="grey lighten-4">

                    <v-toolbar elevation="0" class="grey lighten-3">
                      <v-toolbar-title>
                        <span style="cursor:pointer;"
                        @click="$router.push(`/forecasts/${favoris.id_ville}/${sanityze(favoris.libelle_lien)}`)" >{{
                            favoris.libelle_lien
                        }} <template v-if="favoris.code_pays">-
                          {{ favoris.code_pays }}</template></span>
                      </v-toolbar-title>
                      <v-spacer />
                      <v-tooltip top v-if="!isSubscribe(favoris.id_ville)">
                        <template v-slot:activator="{ on, attr }">
                          <v-btn small v-on="on" v-bind="attr" id="v-step-4" elevation="0" :loading="loading"
                            @click="subs()"><v-icon small>mdi-heart-outline</v-icon></v-btn>
                        </template>
                        <span>{{ $t("add_to_favorites") }}</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on, attr }">
                          <v-btn small class="mr-1" v-on="on" v-bind="attr" :loading="loading" elevation="0" right
                            color="primary" @click="unsubs(favoris.id_favoris)"><v-icon small>mdi-heart</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("delete_from_favorites") }}</span>
                      </v-tooltip>
                    </v-toolbar>
                    <v-card-text style="cursor:pointer;"
                      @click="$router.push(`/forecasts/${favoris.id_ville}/${sanityze(favoris.libelle_lien)}/resum`)">
                      <v-row>
                        <v-col md="3" cols="6" class="text-center my-0 py-6"><v-img max-width="200px" contain :src="`${iconUrl(favoris.forecast.ts)}`" /></v-col>
                        <v-col cols="6" md="9" class="pt-13 text-right my-0 py-0">
                          <span class="font-weight-bold display-1">
                            {{ favoris.forecast.tmin }}</span>
                          /
                          <span class="
                          orange--text
                          display-1
                          text--lighten-2
                          font-weight-bold
                        ">{{ favoris.forecast.tmax }}</span><span class="display-1">°C</span><br />
                         
                        </v-col>
                        <v-col cols="12">
                          <v-simple-table hover-disabled dense class="text-center" v-if="favoris.forecast?.hourly" >
                            <tbody>
                              <tr>
                                <template v-for="i in 23" >
                                  <td :key="i" v-if="i >= currentHour">
                                    <template v-if="i == currentHour">
                                      Maint.
                                    </template>
                                    <template v-else>
                                      {{ i }}h
                                    </template>

                                  </td>
                                </template>
                              </tr>
                              <tr  >
                                <template v-for="i in 23" >
                                  <template v-if="favoris.forecast?.hourly[i+1]">
                                    <td class="text-center" :key="i" v-if="i >= currentHour">
                                      <template>
                                        <img width="50px" :src="`${iconUrl(favoris.forecast.hourly[i+1].ts, favoris.forecast.hourly[i+1].is_night ==1 ? true : false )}`" />
                                      </template>
                                    </td>
                                  </template>
                                </template>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                        <v-col>
                          <v-card height="150px" :class="favoris.forecast.apcpsfc.toFixed(2) > 0.0 ? 'blue' : 'accent'" class="lighten-2 text-center">
                            <v-card-text class="white--text py-8">
                              <template v-if="favoris.forecast.apcpsfc.toFixed(2) > 0.0">
                                <v-icon class="white--text">
                                  mdi-weather-pouring
                                </v-icon>
                                <h3>Pluie</h3>
                                  <span class="font-weight-bold display-1">
                              {{ favoris.forecast.apcpsfc.toFixed(2) }}</span>mm<br />
                              </template>
                              <template v-else>
                                <v-icon class="white--text">
                                  mdi-weather-pouring
                                </v-icon>
                                <h3 class="mt-6">
                                  Pas de pluie
                                </h3>
                              </template>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card height="150px" class="blue lighten-3 text-center">
                            <v-card-text class="white--text py-8">
                                <v-icon class="white--text">
                                  mdi-weather-windy
                                </v-icon>
                                <h3>Vents</h3>
                                <small>vent.moy | raf.max</small><br/>
                                  <span class="font-weight-bold" style="font-size:2em;">
                              {{ Math.round(favoris.forecast.wind_speed * 3.6) }} / <span class="red--text">{{Math.round(favoris.forecast.gustsfc * 3.6)}}</span> </span><br/>km/h<br />
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <router-link :to="`/forecasts/${favoris.id_ville}/${sanityze(favoris.libelle_lien)}/resum`" tag="a">{{
                          $t("home.card-more")
                      }}</router-link></v-card-actions>
                  </v-card>
                </v-col>
              </template>
              <template v-else-if="!$airside">
                <v-col v-if="$env.VUE_APP_WHITE_MARK_ID != 'monithermo'">
                  <v-card elevation="0" color="grey lighten-3">
                    <v-card-text>
                      <v-col class="text-center">
                        <h4>{{ $t("home.noFav") }}</h4>
                        <v-col class="py-12">
                          <v-autocomplete v-model="model" prepend-icon="mdi-magnify" :items="items" dense rounded
                            :loading="isLoading" filled :search-input.sync="search" hide-no-data @change="goTo"
                            hide-selected id="v-step-1" item-text="search_nom" :placeholder="$t('city_or_cp')"
                            return-object no-filter></v-autocomplete>
                        </v-col>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>

            </v-row>
            <v-row v-else-if="isLogged">
              <v-col cols="12">
                <AirportAlertCalendar />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <template v-if="!isLogged">
                <v-col cols="12">
                  <Login @logged="getAlerts" />
                </v-col>
              </template>

      <v-col cols="12" v-if="isLogged && !$airside">
        <v-card>
          <v-card-title>
            {{ $t("home.stations") }}
          </v-card-title>
          <v-card-text>
            <v-row>
            <v-col md="3" cols="12" v-for="(station, i) in stationsFiltered" :key="i">
              <v-card elevation="0" :disabled="!station.valide" :to="`/stations/${station.id_station}`"
                :color="station.last_data[0] ? 'info' : 'warning'" class="white--text">
                <v-card-title>
                  <h2>
                    {{ station.libelle_station }}
                  </h2>
                </v-card-title>
                <v-card-text class="white--text">
                  <v-row>
                    <v-col v-if="station.last_data[0]">
                      <b>Température :</b>
                      <span class="font-weight-bold ">
                        {{ Math.round(station.last_data[0].temp) }}</span>°C<br />
                      <b>Précipitation :</b>
                      <span class="font-weight-bold">
                        {{  station.last_data[0].rain != null ? station.last_data[0].rain : '-' }}</span>mm<br />
                      <b>Vent :</b>
                      <span class="font-weight-bold ml-1">
                        {{
                            $airside 
                              ? Math.round(station.last_data[0].wind * 1.9438) + 'knts'
                              : Math.round(station.last_data[0].wind * 3.6) + 'km/h'
                        }}</span><br />
                    </v-col>
                    <v-col v-else>
                      <h2>{{ $t("error.noDataAvailable") }}</h2>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <router-link class="white--text" :to="`/stations/${station.id_station}`" tag="a">{{
                      $t("home.card-more")
                  }}</router-link></v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="isLogged">
        <v-card>
          <v-card-title>
            {{ $t("home.last_alerts") }}
          </v-card-title>
          <v-card-text>

            <v-row>

              <v-col>
                <v-simple-table height="50vh" fixed-header>
                  <tbody>
                    <tr v-for="(alert, i) in alertsList" :key="i">
                      <td>
                        <span>{{
                            moment(alert.date).format("dddd Do MMMM YYYY [à] H[h]mm")
                        }}</span><br />
                        <span v-html="alert.texte_complet"></span>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <router-link to="/alertes/v2/list">Voir plus...</router-link>
          </v-card-actions>
        </v-card>
      </v-col>

      
    </v-row>
    <v-snackbar top color="error" v-model="error">{{ errorMsg }}</v-snackbar>
  </v-container>
</template>

<script>
import moment from 'moment';
import { iconUrl } from '@/iconUrl';
import { mapState } from 'vuex';
import axios from 'axios';

import api from '@/api';
import AirportAlertCalendar from '../components/Airport/AirportAlertCalendar.vue';

export default {
  components: {
    AirportAlertCalendar,
    Login: () => import("@/views/Login.vue"),
  },
  computed: {
    currentHour() {
      return parseInt(moment().format('HH'));
    },
    canSubscribe() {
      let limit = this.$checkLimit("bulletins_pdf");

      if (!limit) return true;

      if (this.user.bulletins_pdf.length >= limit) {
        return false;
      } else {
        return true;
      }
    },
    stationsFiltered() {
      if (!this.user.stations) return [];
      return this.user.stations.filter((map) => map.valide);
    },
    ...mapState(["user", "isLogged"]),
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.entries.map((chunk) =>
        chunk.cp
          ? {
            ...chunk,
            search_nom: `${chunk.nom} (${chunk.cp.slice(0, 2)}) ${chunk.iso}`,
          }
          : { ...chunk, search_nom: `${chunk.nom} ${chunk.iso}` }
      );
    },
  },
  data: () => ({
    entries: [],
    model: null,
    search: null,
    isLoading: false,
    moment: moment,
    alertsList: [],
    error: false,
    loading: false,
    errorMsg: false,
    bulletin: null,
  }),
  methods: {
    sanityze(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-zA-Z0-9]/g, "-")
        .toLowerCase();
    },
    // function who get user 
    async getUser() {
      try {
        let user = await api.users.get(this.user.id_user, this.$wm.flag);
        this.$store.dispatch("setUser", user);
      } catch (err) {
        console.error(err.message);
      }
    },
    iconUrl,
    async unsubs(id_fav) {
      this.loading = true;
      // console.log('unsub', id_fav);

      try {
        await api.users.unsubsfavorite(
          this.user.id_user,
          id_fav
        );

        let user = await api.users.get(this.user.id_user, this.$wm.flag);

        this.$store.dispatch("setUser", user);
      } catch (err) {
        console.error(err.message);
      }
      this.loading = false;
    },
    isSubscribe(geoname) {
      let find = this.user.favoris.find(
        (map) => map.id_ville == geoname
      );

      return find ? true : false;
    },
    goTo() {
      this.$router.push(`/forecasts/${this.model.geoname}/${this.model.nom}/resum`);
    },
    async getAlerts() {
      this.alertsList = await api.alert.get(this.user.id_user);
    },
    async getBulletin() {

      const apiMy = process.env.VUE_APP_MY_API_URI;
      const poi = this.$store.state.airport.poi;

      const humanBulletin = await api.bulletin.meteorologist(poi);
      if(humanBulletin.length !== 0) {
        const title = `<p><em>${humanBulletin[0].title}</em></p>`;
        this.bulletin = title + humanBulletin[0].content;
        return;
      }
      
      let query = `${apiMy}/api/airport/bulletinAI/${poi}`

      await axios.get(query).then(response => {
        if (response.data.bulletin!==null) {
          const signature = `<p><em>Powered from ${response.data.model}, run ${response.data.run} by Previmeteo AI</em></p>`;
          this.bulletin = response.data.bulletin + signature;
        }
      }).catch(error => {
        console.log(error);
      });
    },
  },
  activated() {
        if(this.$airside) {
            if (this.user.id_user) {
                this.getUser();
                this.getBulletin();
            }
        }
    },
  mounted() {
    if (this.user.id_user) {
      this.getAlerts();
      this.getUser();
    }

    if (this.$route.query.error) {
      this.error = true;
      this.errorMsg = this.$route.query.error;
    }
  },
  name: "Home",
  watch: {
    search(val) {
      this.isLoading = true;

      // Lazily load input items
      api.cities
        .search(val)
        .then((res) => {
          this.entries = res;
          this.count = res.length;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
tr:hover {
  background: transparent !important;
}
</style>