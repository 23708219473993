/* pe-eslint-dis */
export const iconUrl = (ts, night = false) => {
    let code = null;
    let suffix = night ? "n" : "d";
    if (ts == 0) code = "01" + suffix;
    if (ts == 1) code = "02" + suffix;
    if (ts == 2) code = "03" + suffix;
    if (ts == 3) code = "04";
    if (ts == 4) code = "04";
    if (ts == 5) code = "10";
    if (ts == 6) code = "40" + suffix;
    if (ts == 7) code = "09";
    if (ts == 8) code = "41" + suffix;
    if (ts == 9) code = "13";
    if (ts == 10) code = "13";
    if (ts == 11) code = "15";
    if (ts == 12) code = "06" + suffix;
    return (
      "https://cdn.jsdelivr.net/gh/YR/weather-symbols@6.0.2/dist/svg/" +
      code +
      ".svg"
    );
  };
  const table = [
    "beau",
    "ciel voilé",
    "nuageux",
    "couvert",
    "maussade",
    "pluies",
    "averses",
    "pluvieux",
    "pluies+",
    "neige",
    "pluie, neige",
    "orageux"
  ];
  export const iconTxt = ts => table[ts];
  export default { iconUrl, iconTxt };
  export const airsideIconUrl = (ts, night = false) => {
    let code = null;
    let suffix = night ? "-alt" : "";
    if (ts == 0) code = "1";
    if (ts == 1) code = "2";
    if (ts == 2) code = "3";
    if (ts == 3) code = "4";
    if (ts == 4) code = "5";
    if (ts == 5) code = "6";
    if (ts == 6) code = "7";
    if (ts == 7) code = "8";
    if (ts == 8) code = "9";
    if (ts == 9) code = "10";
    if (ts == 10) code = "11";
    if (ts == 11) code = "12";
    if (ts == 12) code = "13";
    if (ts == 13) code = "14";
    if (ts == 14) code = "15";
    if (ts == 15) code = "16";
    if (ts == 16) code = "17";
    if (ts == 17) code = "18";
    if (ts == 18) code = "19";
    if (ts == 19) code = "20";
    if (ts == 20) code = "21";
    if (ts == 21) code = "22";
    if (ts == 22) code = "23";
    if (ts == 23) code = "24";
    if (ts == 24) code = "25";
    if (ts == 25) code = "26";
    if (ts == 26) code = "27";
    if (ts == 27) code = "28";
    if (ts == 28) code = "29";
    if (ts == 29) code = "30";
    if (ts == 30) code = "31";
    if (ts == 31) code = "32";
    if (ts == 32) code = "33";
    if (ts == 33) code = "34";
    if (ts == 34) code = "35";
    if (ts == 35) code = "36";
    if (ts == 36) code = "37";
    if (ts == 37) code = "38";
    if (ts == 38) code = "39";
    if (ts == 39) code = "40";
    if (ts == 40) code = "41";
    if (ts == 41) code = "42";
    if (ts == 42) code = "43";
    if (ts == 43) code = "44";
    if (ts == 44) code = "45";
    if (ts == 45) code = "46";
    if (ts == 46) code = "47";
    if (ts == 47) code = "48";
    if (ts == 48) code = "49";
    if (ts == 49) code = "50";
    if (ts == 50) code = "51";
    if (ts == 51) code = "52";
    if (ts == 52) code = "53";
    if (ts == 53) code = "54";
    if (ts == 54) code = "55";
    if (ts == 55) code = "56";
    if (ts == 56) code = "57";
    if (ts == 57) code = "58";
    if (ts == 58) code = "59";
    if (ts == 59) code = "60";
    if (ts == 60) code = "61";
    if (ts == 61) code = "62";
    if (ts == 62) code = "63";
    if (ts == 63) code = "64";
    return (
      "/pictos/ts64/ts64" +
      suffix + "(" + code + ")" +
      ".png"
    );
  };